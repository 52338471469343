.content {
    background-color: white;
    border-top: 1px solid #e8edee;
    line-height: 1.5;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    width: min(100%, 960px);
}

.content a {
    color: inherit;
}

.content-title {
    font-size: 24px;
    margin-bottom: 10px;
}

.content-links {
    margin: 0;
    padding-left: 20px;
}

.content-links li {
    margin: 10px 0;
}

.content-container {
    display: flex;
}

.notice {
    background-color: #231f20;
    color: white;
    line-height: 1.5;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    width: min(100%, 960px);
}

.notice-container {
    background-color: #231f20;
    display: flex;
}

.page-container {
    flex: 1 1 auto;
}

.page-title {
    font-size: 32px;
}

.video-container {
    border: 0;
    max-width: 100%;
}
