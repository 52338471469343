.cqc-rating {
  margin-left: auto;
}

.cqc-rating-image {
  height: 100px;
}

.footer {
  align-items: center;
  background-color: #005eb8;
  color: white;
  display: flex;
  flex: 0 1 auto;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  width: min(100%, 960px);
}

@media screen and (max-width: 960px) {
  .footer {
    flex-direction: column !important;
  }

  .cqc-rating {
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
  }
}

.footer a {
  color: inherit;
}

.footer p {
  margin: 0;
  padding: 0;
}

.footer-container {
  background-color: #005eb8;
  display: flex;
}

.footer-links {
  gap: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-links li {
  margin: 1rem 0;
}
